import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#212b35';

const BetBtcPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf accepté au casino Hybride BTC – BetBTC.io"
      description="Dépose avec le Neosurf au casino BetBTC – tout nouveau Hybride Bitcoin Casino. Dépose et retire en monnaie fiduciaire et en crypto-monnaie."
      alternateLanguagePages={[
        {
          path: 'casino/betbtc',
          language: 'en'
        },
        {
          path: 'casino/betbtc',
          language: 'fr'
        },
        {
          path: 'casino/betbtc',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Hybride Bitcoin Casino qui accepte le mode de paiement Neosurf" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Casino BetBTC qui accepte Neosurf</h1>
            <p className="textColorLight">Le BetBTC est un <strong>casino 'hybride' fiduciaire et crypto</strong>, ce qui signifie que ton compte sera en monnaie fiduciaire, cependant tu pourras déposer et retirer avec les deux méthodes de transfert fiduciaire et BITCOIN, qui sera automatiquement échangé à sa valeur fiduciaire ! Avec une création moderne, une plateforme rapide, une vaste gamme de jeux et les meilleurs Bonus, le Casino BetBTC a vraiment tout pour plaire ! Oh oui, il accepte des joueurs du monde entier ! Inscris-toi aujourd’hui, dépose avec le Neosurf et réclame ton <strong>bonus de bienvenue spécial NEOSURF</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">BONUS DE BIENVENUE SPÉCIAL NEOSURF DE BETBTC</h2>
            <ul>
              <li>125% jusqu’à 500 $ / € &amp; 125 tours gratuits sans mise</li>
              <li>Les tours gratuits sont sur le The Boomanji / Kawaii Kitty / Dragon Kings (le créneau que tu ouvres en premier parmi ceux proposés)</li>
              <li>Utilise le <strong>code: NEO125+125</strong></li>
              <li>Le dépôt minimum est de 20 €<br />La cagnotte est fois trente (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">OUVRIR CASINO BETBTC</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Offre de Bonus de Bienvenue exclusive de BetBTC pour les dépôts de Neosurf" />
        </div>
      </div>
    </div>
  </>
)

export default BetBtcPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/betbtc/neosurf_betbtc.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/betbtc/neosurf_betbtc_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
